// todo перевести на обновленный селект из https://jira.hh.ru/browse/PORTFOLIO-32101
import { useCallback, useState, useRef } from 'react';

import { Cell, CellText, Drop, Link, useBreakpoint } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16, ChevronUpOutlinedSize16, CheckOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useGetEmployerVacancies from 'lux/models/employerVacancySearch/useGetEmployerVacancies';
import { CriteriaKey, OrderByOption } from 'lux/models/search/searchCriteria.types';
import useNovaSortUpdate from 'src/components/NovaFilters/hooks/useNovaSortUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    [OrderByOption.PublicationTime]: 'employer.settings.searchpublication.sort.date',
    [OrderByOption.SalaryDesc]: 'search.order.salary_desc',
    [OrderByOption.SalaryAsc]: 'search.order.salary_asc',
    [OrderByOption.Relevance]: 'search.order.relevance',
};

const SearchOrder: TranslatedComponent = ({ trls }) => {
    const { getVacanciesByCriteria } = useGetEmployerVacancies();
    const setNovaFilterSort = useNovaSortUpdate();
    const { isMobile } = useBreakpoint();

    const value = useSelector((state) => {
        const value = state.employerVacancySearch.criteria?.[CriteriaKey.OrderBy];
        return Object.values(OrderByOption).includes(value) ? value : OrderByOption.Relevance;
    });
    const options = useSelector((state) => state.vacancySearchDictionaries.orderBy);

    const [isDropVisible, setDropVisible] = useState(false);
    const selectorHost = useRef(null);

    const handleChange = useCallback(
        (value: OrderByOption) => {
            setNovaFilterSort('orderBy', value);
            getVacanciesByCriteria({ [CriteriaKey.OrderBy]: value });
        },
        [getVacanciesByCriteria, setNovaFilterSort]
    );

    if (!options || isMobile) {
        return null;
    }
    return (
        <>
            <Drop
                visible={isDropVisible}
                activatorRef={selectorHost}
                onClose={() => setDropVisible(false)}
                placement="bottom-left"
                forcePlacement
            >
                {options.map((option) => (
                    <Cell
                        key={option}
                        vertPadding
                        horPadding
                        roundedBorder
                        onClick={() => {
                            setDropVisible(false);
                            if (option !== value) {
                                handleChange(option);
                            }
                        }}
                        data-qa={`order-by-${option}`}
                        right={value === option ? <CheckOutlinedSize16 initial="accent" /> : undefined}
                    >
                        <CellText>{trls[TrlKeys[option]]}</CellText>
                    </Cell>
                ))}
            </Drop>
            <span ref={selectorHost} data-qa="order-by-menu">
                <Link
                    typography="label-3-regular"
                    style="neutral"
                    onClick={() => setDropVisible(!isDropVisible)}
                    iconRight={isDropVisible ? ChevronUpOutlinedSize16 : ChevronDownOutlinedSize16}
                >
                    {trls[TrlKeys[value ?? OrderByOption.Relevance]]}
                </Link>
            </span>
        </>
    );
};

export default translation(SearchOrder);
