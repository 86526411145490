import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { push } from 'connected-react-router';

import { useSelector } from 'src/hooks/useSelector';

import { EmployerPageTab, isEmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import useTabUrl, { getRouteTab } from 'src/components/Employer/PageTabs/useTabUrl';

interface UseCurrentTab {
    (): { currentTab: EmployerPageTab; setCurrentTab: (newTab: string) => void };
}

const useCurrentTab: UseCurrentTab = () => {
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const match = useRouteMatch<{ 0: string; 1: string }>();
    const subRoute = match.params[1];
    const currentTab = getRouteTab(subRoute);
    const makeTabUrl = useTabUrl();

    const dispatch = useDispatch();
    const setCurrentTab = useCallback(
        (newTab: string) => {
            if (!isEmployerPageTab(newTab) || newTab === currentTab) {
                return;
            }
            if (hasChameleon) {
                dispatch(push(makeTabUrl(newTab)));
                return;
            }
            dispatch(push(makeTabUrl(newTab), null, { cancelFetchingData: true }));
        },
        [currentTab, dispatch, hasChameleon, makeTabUrl]
    );

    return useMemo(() => ({ currentTab, setCurrentTab }), [currentTab, setCurrentTab]);
};

export default useCurrentTab;
