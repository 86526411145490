import { Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import TotalVacanciesFound from 'src/components/VacancySearchHeader/components/TotalVacanciesFound';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    vacancyMany: 'vacancy.many',
    vacancySome: 'vacancy.some',
    vacancyOne: 'vacancy.one',
};

const VacancyCount: TranslatedComponent = ({ trls }) => {
    const totalResults = useSelector((state) => state.employerVacancySearch.resultsFound);
    const hasTextFilter = useSelector((state) => !!state.employerVacancySearch.criteria?.[CriteriaKey.Text]);
    const hasUsedNovaFilters = useSelector(({ employerVacancySearch }) => !!employerVacancySearch.totalUsedFilters);
    const { isMobile } = useBreakpoint();
    const vacanciesTrls = [trls[TrlKeys.vacancyOne], trls[TrlKeys.vacancySome], trls[TrlKeys.vacancyMany]];

    if (!totalResults || (!hasUsedNovaFilters && !hasTextFilter)) {
        return null;
    }

    return (
        <Text style="secondary" typography="label-3-regular">
            {isMobile ? (
                `${Strings.numConversion(totalResults, vacanciesTrls)}`
            ) : (
                <TotalVacanciesFound count={totalResults} />
            )}
        </Text>
    );
};

export default translation(VacancyCount);
