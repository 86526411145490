import { useEffect, useState, useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';

import SessionStorageWrapper from 'bloko/common/storage/SessionStorageWrapper';

import { addProfRoles, resetToDefault } from 'lux/models/vacanciesGroupsByRegion';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import {
    FilterParams,
    VacanciesGroup,
    VacancyGroupsState,
    PayloadFilterParams,
} from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import VacanciesGroupsByRoles from 'src/components/Employer/VacanciesGroupsByRoles';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import SkeletonLoader from 'src/components/Employer/VacanciesFilter/SkeletonLoader';

declare global {
    interface FetcherGetApi {
        '/shards/employerview/vacancies_groups_json': {
            queryParams: FilterParams;
            response: VacanciesGroup | VacancyGroupsState;
        };
    }
}

enum GroupType {
    Region = 'Region',
    Role = 'Role',
}

interface VacanciesFilterProps {
    overlayContent?: boolean;
}

const VacanciesFilter: FC<VacanciesFilterProps> = ({ overlayContent }) => {
    const [groupsType, setGroupsType] = useState(GroupType.Region);
    const [loading, setLoading] = useState(true);
    const [filterParams, setFilterParams] = useState({} as FilterParams);
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const { vacanciesFilter: queryParamFilters } = useSelector(({ router }) => router.location.query);

    const setVacanciesBlockFilter = useCallback(async (): Promise<void> => {
        try {
            setLoading(true);
            const filtersSource = !overlayContent
                ? SessionStorageWrapper.getItem('VacancyBlockFilter')
                : queryParamFilters && decodeURIComponent(queryParamFilters);
            const payloadFilterParams = JSON.parse(filtersSource || '{ "detail": null }') as PayloadFilterParams;
            if (
                !payloadFilterParams?.detail ||
                Number(employerId) !== Number(payloadFilterParams.detail.currentEmployerId)
            ) {
                return;
            }
            const params: FilterParams = payloadFilterParams.detail;
            const response = await fetcher.get('/shards/employerview/vacancies_groups_json', {
                params,
            });
            if (params.groupByRegion) {
                dispatch(resetToDefault(response as VacancyGroupsState));
                setFilterParams({} as FilterParams);
                setGroupsType(GroupType.Region);
            } else {
                dispatch(addProfRoles(response));
                setFilterParams(params);
                setGroupsType(GroupType.Role);
            }
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        } finally {
            setLoading(false);
        }
    }, [addNotification, dispatch, employerId, overlayContent, queryParamFilters]);

    const handleVacanciesBlockFilter: EventListener = useCallback(
        () => setVacanciesBlockFilter(),
        [setVacanciesBlockFilter]
    );

    useEffect(() => {
        window.addEventListener('VacancyBlockFilter', handleVacanciesBlockFilter);

        return function cleanup() {
            window.removeEventListener('VacancyBlockFilter', handleVacanciesBlockFilter);
        };
    }, [handleVacanciesBlockFilter]);

    useEffect(() => {
        void setVacanciesBlockFilter();
    }, [setVacanciesBlockFilter]);

    if (loading) {
        return <SkeletonLoader />;
    }

    if (groupsType === GroupType.Role) {
        return <VacanciesGroupsByRoles filterParams={filterParams} />;
    }
    return <VacanciesGroupsByRegion hasChameleon />;
};

export default VacanciesFilter;
