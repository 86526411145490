import { FC } from 'react';

import { NumberPages, VSpacing } from '@hh.ru/magritte-ui';

import useGetEmployerVacancies from 'lux/models/employerVacancySearch/useGetEmployerVacancies';
import getLastPage from 'src/components/VacancySearchContent/utils/getLastPage';
import { useSelector } from 'src/hooks/useSelector';

import styles from './pagination.less';

interface PaginationProps {
    scrollWidgetTop: () => void;
}

const Pagination: FC<PaginationProps> = ({ scrollWidgetTop }) => {
    const paging = useSelector(({ employerVacancySearch }) => employerVacancySearch.paging);
    const { getVacanciesByPageNumber } = useGetEmployerVacancies();

    if (!paging || !getVacanciesByPageNumber) {
        return null;
    }

    const goToPageByPageNumber = (page: number) => {
        getVacanciesByPageNumber(page);
        scrollWidgetTop();
    };

    const formatPagingToNumberPages = {
        first: 1,
        last: getLastPage(paging),
        current: Number(paging.pages.find(({ selected }) => selected)?.text ?? 0),
        onChange: goToPageByPageNumber,
    };

    return (
        <div className={styles.center}>
            <VSpacing default={40} />
            <NumberPages {...formatPagingToNumberPages} />
        </div>
    );
};
export default Pagination;
