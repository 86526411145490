import { FC, PropsWithChildren, useMemo, useCallback, useEffect } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { UserType } from 'lux/models/userType';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

import PageTabsContext, { EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import PageTabsMobileSupport from 'src/components/Employer/PageTabs/PageTabsMobileSupport';
import useCurrentTab from 'src/components/Employer/PageTabs/useCurrentTab';

const PageTabsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { currentTab, setCurrentTab } = useCurrentTab();
    const isMobileView = [Breakpoint.XS, Breakpoint.S].includes(useBreakpoint());
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const userType = useSelector((state) => state.userType);
    const isApplicantOrAnonymous = [UserType.Anonymous, UserType.Applicant, UserType.NedoUser].includes(userType);
    const isEmployerTabsExp = useExperiment('brandy_employer_page_tabs', isApplicantOrAnonymous);

    const showContentForTab = useCallback(
        (tab: EmployerPageTab) => !isEmployerTabsExp || currentTab === tab,
        [currentTab, isEmployerTabsExp]
    );

    useEffect(() => {
        if (hasChameleon && !isMobileView && currentTab === EmployerPageTab.Vacancies) {
            setCurrentTab(EmployerPageTab.Description);
        }
    }, [currentTab, hasChameleon, isMobileView, setCurrentTab]);

    const value = useMemo(
        () => ({
            currentTab,
            setCurrentTab,
            isEmployerTabsExp,
            isMobileView: isMobileView && isEmployerTabsExp,
            showContentForTab,
        }),
        [currentTab, isEmployerTabsExp, isMobileView, setCurrentTab, showContentForTab]
    );

    return (
        <PageTabsContext.Provider value={value}>
            <PageTabsMobileSupport>{children}</PageTabsMobileSupport>
        </PageTabsContext.Provider>
    );
};

export default PageTabsProvider;
