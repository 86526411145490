import { Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import FormItem from 'bloko/blocks/form/FormItem';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { format } from 'bloko/common/trl';

import { Status } from 'lux/models/employer/employerLogo';
import CdnImg from 'src/components/CdnImg';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import HeaderTitle from 'src/components/Employer/HeaderTitle';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import Content from 'src/components/Employer/Sidebar/Content';
import LogoEdit from 'src/components/Employer/Sidebar/LogoEdit';
import Subscribe from 'src/components/Employer/Sidebar/Subscribe';
import WantWorkHere from 'src/components/Employer/Sidebar/WantWorkHere';
import deleteLogo from 'src/components/Employer/Sidebar/deleteLogo';
import uploadLogo from 'src/components/Employer/Sidebar/uploadLogo';

import styles from './want-work-here.less';

const Sidebar = ({
    trls,
    editMode,
    hideTitle,
    showConstructorButton,
    showConstructorGuideLink,
    hideEditSidebarButton,
    employerId,
    site,
    logoUrl,
    address,
    vacanciesCount,
    industries,
    hrBrand,
    badges,
    companyName,
    accepted,
    isTrusted,
    insider,
    logoStatus,
    uploadLogo,
    deleteLogo,
    colors,
    buttons,
    hasConstructorService,
    hasEditAccess,
    previousEmployerTargets,
    employerLogoEditSettings,
}) => {
    const showLogoContainer = editMode || logoUrl;

    const containerStyle = colors?.background ? { backgroundColor: `#${colors.background}` } : {};
    const isChangedBackground = !!colors?.background;
    const headerStyle = isChangedBackground && colors?.header ? { color: `#${colors.header}` } : {};
    const linkStyle = isChangedBackground && colors?.link ? { color: `#${colors.link}` } : {};

    const constructorLink = '/employer/constructor?from=sidebar&hhtmFromLabel=sidebar';
    const isZarplata = useIsZarplataPlatform();

    const previousTargetIds = previousEmployerTargets?.map(({ id }) => id);
    const previousTargetIdsUnique = [...new Set(previousTargetIds)];
    const resumesInfo = useSelector((state) => state.applicantInfo);
    const visibleResumesCount = resumesInfo.visible;
    let countResumes = previousTargetIdsUnique.length;
    if (countResumes > visibleResumesCount) {
        // если у пользователя есть скрытые резюме
        countResumes = visibleResumesCount;
    }

    const trlCountResumes = Sidebar.trls[`resumeCount${countResumes}`];
    const textCountResumes = trlCountResumes ? trls[trlCountResumes] : countResumes.toString();

    const infoTextWantWorkHere = (
        <>
            {countResumes > 0 && (
                <Text strong>
                    <span className={styles.wantWorkHereText} style={headerStyle}>
                        {trls[Sidebar.trls.wantWorkHereText]}
                    </span>
                </Text>
            )}

            {countResumes > 1 && (
                <Link
                    to={`/applicant/wantwork/payment?employerId=${employerId}&from=employer_sidebar&hhtmFromLabel=employer_sidebar`}
                    style={linkStyle}
                >
                    {format(trls[Sidebar.trls.wantWorkHereCountResumesText], {
                        '{0}': textCountResumes,
                    })}
                </Link>
            )}
            {countResumes > 0 && <VSpacing default={24} />}
        </>
    );

    const wantWorkHereBlock = buttons.workHere && (
        <>
            {infoTextWantWorkHere}
            {(visibleResumesCount !== countResumes || visibleResumesCount === 0) && (
                <div className="employer-sidebar-button">
                    <WantWorkHere stretched />
                </div>
            )}
        </>
    );

    const forEdit = () => (
        <Fragment>
            {hasEditAccess && !hideEditSidebarButton && (
                <FormItem>
                    <Button Element={Link} to="/employer/edit/sidebar" data-qa="edit-sidebar-button" stretched>
                        {trls[Sidebar.trls.editSidebar]}
                    </Button>
                </FormItem>
            )}
            {showConstructorGuideLink && (
                <FormItem>
                    <div className="employer-sidebar-constructor-guide-link">
                        <BlokoLink
                            disableVisited
                            Element={Link}
                            to={trls[Sidebar.trls.constructorGuideLink]}
                            target="_blank"
                            onClick={() => Analytics.sendHHEventButtonClick('employerConstructorGuide')}
                        >
                            {trls[Sidebar.trls.constructorGuide]}
                        </BlokoLink>
                    </div>
                </FormItem>
            )}
            {showConstructorButton && (
                <FormItem>
                    <HoverTip
                        host={!process.env.LUX_SERVER ? document.body : null}
                        render={() => trls[Sidebar.trls.toConstructorLinkTooltip]}
                    >
                        <Button Element={Link} to={constructorLink} stretched kind={ButtonKind.Primary}>
                            {hasConstructorService && !isZarplata
                                ? trls[Sidebar.trls.toPaidConstructorLink]
                                : trls[Sidebar.trls.toConstructorLink]}
                        </Button>
                    </HoverTip>
                </FormItem>
            )}
        </Fragment>
    );

    const forPreview = () => (
        <Fragment>
            {wantWorkHereBlock}
            {!!buttons.saveSearch && (
                <div className="employer-sidebar-button">
                    <Subscribe stretched />
                </div>
            )}
            {!!buttons.complain && (
                <div className="employer-sidebar-button">
                    <ContainerForMicroFrontend place="employerComplain" />
                </div>
            )}
        </Fragment>
    );

    let invertDefaultText = false;

    if (colors?.background) {
        const rgb = colors.background.match(/.{2}/gi).map((value) => parseInt(value, 16));
        // переводим RGB палитру в HSV, нам оттуда нужен только V - светлость цвета
        const hsV = Math.max(...rgb) / 255;
        invertDefaultText = hsV < 0.65;
    }

    const currentBreakpoint = useBreakpoint();
    const isSmallScreen = [Breakpoint.XS, Breakpoint.S].includes(currentBreakpoint);
    const showHeaderReviewsWidget = accepted && isSmallScreen;

    return (
        <div className="employer-sidebar-wrapper" data-qa="sidebar-background-color" style={containerStyle}>
            <div
                className={classnames('employer-sidebar-header', {
                    'employer-sidebar-header_hide-title-on-xs': hideTitle,
                })}
            >
                <HeaderTitle inverted={invertDefaultText} isMainHeader={isSmallScreen} />
                {showHeaderReviewsWidget && <EmployerReviewsWidgetSmall />}
            </div>
            <div className="employer-sidebar" style={containerStyle}>
                {showLogoContainer && (
                    <div className="employer-sidebar__logo-container">
                        {logoUrl && (
                            <div className="employer-sidebar__logo-img-container">
                                <CdnImg
                                    loading="lazy"
                                    className="employer-sidebar__logo"
                                    path={logoUrl}
                                    alt={companyName}
                                    data-qa="company-logo-image"
                                />
                            </div>
                        )}
                        {editMode && (
                            <LogoEdit
                                logoUrl={logoUrl}
                                loading={logoStatus === Status.Fetching}
                                logoOnLoad={uploadLogo}
                                logoOnDelete={deleteLogo}
                                invertDefaultText={invertDefaultText}
                                employerLogoEditSettings={employerLogoEditSettings}
                            />
                        )}
                    </div>
                )}
                <Content
                    withoutLogo={!editMode ? !logoUrl : false}
                    address={address}
                    site={site}
                    accepted={accepted}
                    isTrusted={isTrusted}
                    vacanciesCount={vacanciesCount}
                    hrBrand={hrBrand}
                    badges={badges}
                    industries={industries}
                    insider={insider}
                    colors={colors}
                />
                <div className="employer-sidebar__footer">
                    <VSpacing default={24} />
                    {editMode ? forEdit() : forPreview()}
                </div>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    trls: PropTypes.object,

    withoutColors: PropTypes.bool,
    editMode: PropTypes.bool,
    hideTitle: PropTypes.bool,
    showConstructorButton: PropTypes.bool,
    showConstructorGuideLink: PropTypes.bool,
    hideEditSidebarButton: PropTypes.bool,

    employerId: PropTypes.number,
    site: PropTypes.shape({
        hostname: PropTypes.string,
        href: PropTypes.string,
    }),
    logoUrl: PropTypes.string,
    address: PropTypes.string,
    vacanciesCount: PropTypes.number,
    industries: PropTypes.arrayOf(PropTypes.object),
    hrBrand: PropTypes.object,
    badges: PropTypes.object,
    companyName: PropTypes.string,
    accepted: PropTypes.bool,
    isTrusted: PropTypes.bool,
    insider: PropTypes.object,

    logoStatus: PropTypes.string,
    uploadLogo: PropTypes.func,
    deleteLogo: PropTypes.func,

    colors: PropTypes.shape({
        background: PropTypes.string,
        header: PropTypes.string,
        link: PropTypes.string,
        text: PropTypes.string,
    }),

    buttons: PropTypes.shape({
        saveSearch: PropTypes.shape({
            emailRequired: PropTypes.bool,
            count: PropTypes.number,
            userEmail: PropTypes.string,
        }),
        workHere: PropTypes.bool,
        complain: PropTypes.shape({
            success: PropTypes.bool,
        }),
    }),

    hasConstructorService: PropTypes.bool,
    hasEditAccess: PropTypes.bool,
    previousEmployerTargets: PropTypes.arrayOf(PropTypes.object),
    employerLogoEditSettings: PropTypes.shape({
        maxSizeBytes: PropTypes.number,
        allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
        maxWidth: PropTypes.number,
        maxHeight: PropTypes.number,
    }),
};

Sidebar.trls = {
    toConstructorLink: 'employer.edit.simple.sidebar.button.constructor',
    toPaidConstructorLink: 'employer.edit.simple.sidebar.button.constructor.paid',
    toConstructorLinkTooltip: 'employer.edit.simple.sidebar.button.constructor.tooltip',
    editSidebar: 'employer.edit.sidebar.editSidebar',
    constructorGuide: 'employer.edit.sidebar.constructorGuide',
    constructorGuideLink: 'employer.edit.sidebar.constructorGuideLink',
    wantWorkHereText: 'employer.wantWorkHere.text',
    wantWorkHereCountResumesText: 'employer.wantWorkHere.textCountResumes',
    resumeCount1: 'employer.wantWorkHere.countResumes.one',
    resumeCount2: 'employer.wantWorkHere.countResumes.two',
    resumeCount3: 'employer.wantWorkHere.countResumes.three',
    resumeCount4: 'employer.wantWorkHere.countResumes.four',
    resumeCount5: 'employer.wantWorkHere.countResumes.five',
};

export default connect(
    (
        {
            employerLogo,
            employerInfo,
            employerButtons,
            vacanciesGroupsByRegion,
            employerConstructor,
            employerInsiderInterview,
            hasEditSidebarButton,
            employerLogoEditSettings,
            activeEmployerVacancyCount,
        },
        { withoutColors }
    ) => ({
        ...employerLogo,
        employerId: employerInfo.id,
        site: employerInfo.site,
        address: employerInfo.address,
        industries: employerInfo.industries,
        hrBrand: employerInfo.hrBrand,
        badges: employerInfo.badges,
        companyName: employerInfo.name,
        accepted: employerInfo.accepted,
        isTrusted: employerInfo.isTrusted,
        vacanciesCount: vacanciesGroupsByRegion?.count ?? activeEmployerVacancyCount,
        colors: !withoutColors && employerConstructor.sidebarColors,
        buttons: employerButtons,
        insider: employerInsiderInterview,
        hasConstructorService: employerConstructor.hasService,
        hasEditAccess: hasEditSidebarButton,
        previousEmployerTargets: employerInfo.previousEmployerTargets,
        employerLogoEditSettings,
    }),
    { uploadLogo, deleteLogo }
)(translation(Sidebar));
