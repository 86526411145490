import { setLogoStatus, setLogoUrl, Status } from 'lux/models/employer/employerLogo';
import defaultError from 'src/components/Notifications/DefaultError';
import fetcher from 'src/utils/fetcher';

export default (addNotification) => (dispatch) => {
    dispatch(setLogoStatus(Status.Fetching));
    return fetcher.delete('/employer/edit/logo').then(
        () => {
            dispatch([setLogoUrl(null), setLogoStatus(Status.Success)]);
        },
        () => {
            dispatch(setLogoStatus(Status.Fail));
            addNotification(defaultError);
        }
    );
};
